@import url('https://fonts.googleapis.com/css?family=Nunito');

@import "~mprogress/mprogress.min.css";
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
.required{
  color: red;
}

.fc-week  {
  height: 45px !important;
}
.fc-scroller {
  height:auto !important;
}
.fc-basic-view .fc-body .fc-row {
  min-height: 3em;
}
.fc-day-top {
  text-align: center;
  padding: 10px !important;
}
.fc-day-number {
  float:none !important;
}
.large_icon{
  font-size: 50px !important;
}

/* rating */
#star-1 {
  display: none !important;
}
#rating .stars {
  background: url('/admin/assets/media/stars.png') repeat-x 0 0;
  width: 150px;
}

#rating .stars input[type="radio"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
#rating .stars input[type="radio"].star-5:checked ~ span {
  width: 100%;
}
#rating .stars input[type="radio"].star-4:checked ~ span {
  width: 80%;
}
#rating .stars input[type="radio"].star-3:checked ~ span {
  width: 60%;
}
#rating .stars input[type="radio"].star-2:checked ~ span {
  width: 40%;
}
#rating .stars input[type="radio"].star-1:checked ~ span {
  width: 20%;
}
#rating .stars label {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0!important;
  padding: 0!important;
  text-indent: -999em;
  float: left;
  position: relative;
  z-index: 10;
  background: transparent!important;
  cursor: inherit;
}
#rating .stars [type="radio"]:not(:checked) + label::before, [type="radio"]:not(:checked) + label::after {
  border: none !important;
  background: none;
}
#rating .stars [type="radio"]:checked + label::after, .with-gap[type="radio"]:checked + label::after {
  background: none;
  border: none;
}
/*
#rating .stars label:hover ~ span {
background-position: 0 -30px;
}
#rating .stars label.star-5:hover ~ span {
 width: 100% !important;
}
#rating .stars label.star-4:hover ~ span {
width: 80% !important;
}
#rating .stars label.star-3:hover ~ span {
 width: 60% !important;
}
#rating .stars label.star-2:hover ~ span {
 width: 40% !important;
}
#rating .stars label.star-1:hover ~ span {
 width: 20% !important;
} 
*/

#rating .stars span {
  display: block;
  width: 0;
  position: relative;
  top: 0;
  left: 0;
  height: 30px;
  background: url('/admin/assets/media/stars.png') repeat-x 0 -60px;
  -webkit-transition: -webkit-width 0.5s;
  -moz-transition: -moz-width 0.5s;
  -ms-transition: -ms-width 0.5s;
  -o-transition: -o-width 0.5s;
  transition: width 0.5s;
}
.select2-container--default .select2-search--dropdown {
  padding: 15px 0px;
}
.seprateBox {
  margin-bottom: 20px;
  border-bottom: 1px solid #e2dede;
  padding-bottom: 20px;
}
.VrtTop{
  vertical-align: top;
}
.kt-switch input:empty ~ span:before {
  background-color: #d2d3d4;
}
.form-control, .custom-file-label{
  border-color: #d2d3d4;
}
.kt-widget__desc span{
  margin-right:8px;
}
.kt-widget__desc span i{
  margin-right:5px;
}
.notFound{
  padding: 15px;
  text-align: center;
}
.InfoOrdersRight span{
  display: inline-block;;
}
.exchangeIcon {
  color: #299798;
  margin: 0 5px;
}
.kt-notification .kt-notification__item.unread_at{
  background: #e2e3e9;
}
.favorite-checkbox {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  display: inline-block;
}
.favorite-checkbox .kt-checkbox{
  margin-right: 15px;
}
.kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__head .kt-widget__title {
  text-transform: capitalize;
}
.kt-widget__title:hover{
  color: #48465b !important;
}

.widthDown  .dropdown.bootstrap-select {
  width: 130px !important;
}

.kt-portlet.kt-portlet--skin-solid .kt-portlet__body {
  color: #309798;
}
.dropdown-menu.show{z-index: 97;}
.kt-switch.leftSwitchbtn {float: left;width: 54px;}
.col-form-label.VrtTop.rightSwitchText {width: 68%;margin-left: 10px;padding-top: 4px;}
.table.table-bordered {overflow-x: auto;width: 100%;}
.modal-body .responsive_modal{overflow: scroll;}
/*.table-responsive .table.table-bordered {min-height: 600px;}*/
.swal2-content {text-align: left !important;}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body.profileInfo .kt-widget__content .kt-widget__info .kt-widget__label{
  margin-right: 7px;
}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body.profileInfo .kt-widget__content .kt-widget__info {
  justify-content: left;
  align-items: flex-start;
}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body.profileInfo .kt-widget__content .kt-widget__info .kt-widget__data{word-break: break-all;}


.circle:before {
  content: ' \25CF';
  font-size: 20px;
}
.circle.offline{
  color:red;
}
.circle.online{
  color:green;
}

.kt-widget4 .kt-widget4__item{
  align-items: flex-start;
}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info{
  justify-content: left;
}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info .kt-widget__label {
  min-width: 83px;
}
.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info {
  align-items: flex-start;
}
.departMentPT  .kt-portlet__foot{
  padding: 0 !important;
}
.departMentPT .col-lg-6:first-child {
  display: none;
}

.departMentPT .btn.btn-label-primary{
  color: #299798;
}

.departMentPT a.btn.btn-label-primary:focus,
.departMentPT a.btn.btn-label-primary:hover,
.departMentPT a.btn.btn-label-primary.active,
.departMentPT button.btn.btn-label-primary:focus,
.departMentPT button.btn.btn-label-primary:hover,
.departMentPT button.btn.btn-label-primary.active{
  background-color: #299798;
  color: #ffffff;
}
.profile_box .imgbox {
  height: 90px;
  width: 90px;
  display: inline-block;
  overflow: hidden;
  border-radius: 100%;
  text-align: center;
  margin-top: 0;
  position: relative;
}
.profile_box .imgbox:before {
  content: '';
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 1);
  opacity: 0;
  -webkit-transform: scale(.1);
  -moz-transform: scale(.1);
  -ms-transform: scale(.1);
  transform: scale(.1);
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: sonarEffect 1.5s infinite linear;
  -moz-animation: sonarEffect 1.5s infinite linear;
  animation: sonarEffect 1.5s infinite linear;
}
.profile_box .imgbox img {
  width: 100%;
  border-radius: 50%;
}
.boxShadow .overlay_img {
  background: #29979887;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  opacity: 0;
  -webkit-transition: .4s ease;
  transition: .4s ease;
  cursor: pointer;
}
.boxShadow .imgbox span {
  position: absolute;
  margin: 0 auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: transform .5s ease, opacity .7s ease;
  transition: transform .5s ease, opacity .7s ease;
  padding: 40px;
  vertical-align: middle;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
input#profile_pic {
  display: none;
}
.boxShadow .imgbox:hover .overlay_img {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.boxShadow .imgbox:hover span {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: transform .5s ease, opacity 1.3s ease;
  transition: transform .5s ease, opacity 1.3s ease;
  cursor: pointer;
}
.imgbox:hover span i {
  font-size: 22px;
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.kt-widget.kt-widget--user-profile-1 .kt-widget__body .kt-widget__content .kt-widget__info .kt-widget__data {
  word-break: break-all;
}
.swal2-container #swal2-content{
  text-align: center;
}
.list-none{
  padding-left: 0;
}
.list-none li{
  margin-bottom: 7px;
}
.commentCountBox{
  background: #299798;
  color: white;
  width: 15px;
  height: 15px;
  inline-size: 15px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  top: -5px;
  font-size: 10px;
}
.kt-datatable button[title="Completed"]{
  background: yellow !important;
  color: black !important;
}
.kt-datatable button[title="Paid"]{
  background: green !important;
  color: white !important;
}
.kt-datatable button[title="Afsluttet"]{
  background: yellow !important;
  color: black !important;
}
.kt-datatable button[title="Faktureret"]{
  background: green !important;
  color: white !important;
}
.table thead.kt-datatable__head  th{
  vertical-align: top !important;
}
.datalisting .kt-iconbox .kt-iconbox__body .kt-iconbox__desc .kt-iconbox__title .kt-link{
  word-break: break-all;
}

.swal2-container #swal2-content{
	text-align:center;
}
.swal2-content h4 {
    margin-top: 10px;
}
.kt-notification-v2 .badge {
  white-space: normal;
  text-align: left;
  line-height: normal;
  margin-bottom: 5px;
}

.kt-checkbox {
  margin-right: 10px;
}
.hide-me{
  display: none;
}
.kt-widget4 .kt-widget4__item.customtextalign .kt-widget4__title { 
    width: 50%;
    flex-grow: 0; 
}
.kt-widget4 .kt-widget4__item.customtextalign .kt-widget4__number {
    width: 50%;
    text-align: left;
    word-break: break-all;
}
.custmScroll{
    height: 300px;
    overflow-y: scroll !important;
	border-radius: 4px;
	opacity: 1;
}

.page404{
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;
}
.page404 img{
  width:450px;
  margin-bottom: 20px;
}


.fixHead {
  height: auto !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.kt-chat .fixHead .kt-chat__head .kt-chat__left {
  flex-grow: 0;
}
.SpaceRemoveTop {
  margin-top: -40px;
}
.customdataBlink {
  animation: blinkingText 1s infinite;
}
@keyframes blinkingText{
  0%, 49% {
    background-color: rgba(255, 90, 90, 0.68);
  }
  50%, 100% {
    background-color:rgba(255, 102, 102, 0.98);
  }
}
.customdataBlink td{
  color: white;
}
.customdataBlink td .btn{
  color: white;
}
.fc th, .fc td{ opacity: 1;}
.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-mirror-skeleton td{
  border-color: #ddd !important;
}
/*.kt-widget4 .kt-widget4__item .kt-widget4__number{*/
/*  word-break: break-all;*/
/*}*/
/********** Responsive media css ********/

@media  (min-width: 1024px) and (max-width: 1440px){
  .kt-iconbox .kt-iconbox__body .kt-iconbox__desc .kt-iconbox__title .kt-link {font-size: 16px;}
}

@media  (min-width: 1200px) and (max-width: 1400px){
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__username,
.kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__content .kt-widget__section .kt-widget__subtitle{
  font-size: 11px;
}
  .kt-widget.kt-widget--user-profile-1 .kt-widget__head .kt-widget__media img {
    width: 60px;
  }
}
@media screen and (min-width: 1024px){
  /*.table-responsive {min-height: 600px;}*/
}
@media screen and (max-width: 1023px){
  .table.table-bordered {display: block;}
}
@media (min-width: 1025px){
.kt-aside--minimize .kt-aside-menu .kt-menu__nav  .kt-menu__item  .kt-menu__link  .kt-menu__link-icon {
    color: white;
}
}
@media (min-width: 1025px){
.kt-aside--minimize .kt-aside-menu .kt-menu__nav  .kt-menu__item.kt-menu__item--here  .kt-menu__link  .kt-menu__link-icon, .kt-aside--minimize .kt-aside-menu .kt-menu__nav  .kt-menu__item.kt-menu__item--active  .kt-menu__link > .kt-menu__link-icon {
    color: white;
}
}
@media screen and (max-width: 767px){
  .col-form-label.VrtTop.rightSwitchText {
    width: 58%;
    margin-left: 23px;
    padding-top: 4px;
  }
}

@media (max-width: 1024px){
.kt-header-mobile { background-color: #299798 !important;}
}

